import Axios from "axios";
import Cookies from "js-cookie";
import { isPropertyManagerPage, isGroupManagerPage } from "@/main";
Axios.defaults.timeout = 30000;
// 跨域请求，允许保存cookie
Axios.defaults.withCredentials = false;
Axios.defaults.baseURL = '//msi.biyao.com';
Axios.interceptors.request.use(
  config => {
    try {
      config.method = config.isGet ? 'GET' : 'POST';
      if (isPropertyManagerPage) {
        // 物业网1.3 h5页面 使用
        let userInfo = localStorage.getItem('propertyManagerAccountInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          config.headers.token = userInfo.token;
        }
      } else if (isGroupManagerPage) {
        // 必要团长1.0 
        let userInfo = localStorage.getItem('groupManagerAccountInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          config.headers.token = userInfo.token;
        }
      } else if (!config.url.includes("aiapi.biyao.com")) {
        let accountInfo = localStorage.getItem('accountInfo');
        if (accountInfo) {
          accountInfo = JSON.parse(accountInfo);
          config.headers.userId = accountInfo.employee_id;
        }
        config.headers.token = Cookies.get('x-ai-sk') || "";
      }
    } catch (error) {
      console.error("axios用户信息处理失败 error=", error);
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  res => {
    if (!res || res.status != 200) {
      return Promise.reject(res.data.msg || "数据获取失败，请稍后重试！");
    }
    if (res.data.error) {
      if (res.data.error.code == '403' && res.data.success != 1 && (isPropertyManagerPage || isGroupManagerPage)) {
        if (isPropertyManagerPage) {
          localStorage.removeItem('propertyManagerAccountInfo');
          setTimeout(() => {
            window.open(`${window.location.origin}/propertyManager/login`, '_self');
          }, 1000);
        } else if (isGroupManagerPage) {
          localStorage.removeItem('groupManagerAccountInfo');
          setTimeout(() => {
            window.open(`${window.location.origin}/groupManager/login`, '_self');
          }, 1000);
        }
        res.data.errMsg = "请重新登录！";
      } else {
        res.data.errMsg = res.data.error.message;
      }
    }
    return res.data;
  },
  error => {
    return Promise.reject(error);
  }
);
const API = Axios;
export default API;